




















import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserInterface } from 'common-modules/src/store/interface/User';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlButton,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlHome extends Vue {
  currentUser!: UserInterface;

  get isAdmin (): boolean {
    return this.currentUser.role === 'admin';
  }
}
